import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "" }) => {
  return (
<AnimationRevealPage style={{ background: '#fff', margin: 0, padding: 0 }}>
  <Header />
  <Container style={{ 
    background: '#fff', 
    margin: '0 auto', 
    padding: '0', 
    maxWidth: '100%', 
    width: '100%' 
  }}>
    <ContentWithPaddingXl style={{ padding: '0' }}>
      <HeadingRow style={{ margin: 0, padding: 0 }}>
        <Heading style={{ margin: 0 }}>{headingText}</Heading>
      </HeadingRow>
      <Text style={{ margin: 0, padding: 0 }}>
        <iframe
          src="/KVKKForm.pdf#toolbar=0&navpanes=0&scrollbar=0"
          title="Example PDF"
          width="100%"
          height="600px"
          style={{ border: 'none', margin: 0, padding: 0, display: 'block', backgroundColor: '#fff' }}
        />
      </Text>
    </ContentWithPaddingXl>
  </Container>
  <Footer />
</AnimationRevealPage>

  );
};