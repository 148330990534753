import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "TÜKETİCİ HAKLARI – CAYMA – İPTAL İADE KOŞULLARI" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
    <p>Alıcı tacir ise 8 (sekiz), tüketici ise 14 (on dört) gün olmak üzere; ürünü teslim aldıktan sonra belirtilen sürede sevk edildiği şekil, şart, nitelik ve pakette olmak ön şartıyla herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin sözleşmeden cayma hakkına sahiptir. 385 sayılı Vergi Usul Kanunu Genel Tebliği uyarınca iade işlemlerinin yapılabilmesi için alıcının mal ile birlikte teslim edilen satıcıya ait 2 adet faturanın alt kısmındaki iade bölümlerini eksiksiz ve doğru şekilde doldurduktan sonra imzalayarak bir nüshasını ürün ile birlikte satıcıya göndermesi diğer nüshasını da uhdesinde tutması gerekmektedir. Cayma hakkı süresi alıcıya malın teslim edildiği günden itibaren başlar. İade edilen ürün veya ürünlerin geri gönderim bedeli alıcı tarafından karşılanmalıdır.</p>
    <p>Alıcının istekleri ve/veya açıkça onun kişisel ihtiyaçları doğrultusunda hazırlanan ürünler için cayma hakkı söz konusu değildir.</p>
    <p>Alıcının cayma hakkını kullanması halinde satıcı, cayma bildirimini içeren faturanın ürünle birlikte kendisine ulaşmasından itibaren en geç on gün içerisinde almış olduğu toplam bedeli ve varsa tüketiciyi borç altına sokan her türlü belgeyi tüketiciye hiçbir masraf yüklemeden iade edecektir.</p>
    <p>Ambalajı açılmış, kullanılmış, tahrip edilmiş vesaire şekildeki ürünlerin iadesi kabul edilmez. İade, orijinal ambalaj ile yapılmalıdır. Ürünün tüm aksesuarları ve orijinal kutusu ile beraber iade edilmesi gerekmektedir.</p>
    <p>Sehven alınan üründe ve ambalajında herhangi bir açılma, bozulma, kırılma, tahrip, yırtılma, kullanılma ve sair durumlar tespit edildiği hallerde ve ürünün alıcıya teslim edildiği andaki hali ile iade edilememesi durumunda ürün iade alınmaz ve bedeli iade edilmez.</p>
    <p>Ürün iadesi için, durum öncelikli olarak müşteri hizmetlerine iletilmelidir.</p>
    <p>Ürünün iade olarak gönderilme bilgisi, satıcı tarafından müşteriye iletilir. Bu görüşmeden sonra ürün iade ile ilgili bilgileri içeren fatura ile birlikte alıcı adresine teslimatı yapan Kargo şirketi kanalıyla Satıcıya ulaştırmalıdır.</p>
    <p>İade ve değişim işlemlerinde anlaşmalı olduğumuz kargo şirketi ……. Kargo ile "Karşı Ödemeli” olarak gönderebilirsiniz. …… Kargo şirketi haricinde gelen "Karşı Ödemeli” gönderilerde, gönderim ücreti tarafınızdan ödenmektedir.</p>
    <p>İade gönderilerinizde iade kargo kodu olarak mutlaka ……….. numarasını giriniz. Bu sayede kargonuz hızlıca ve sorunsuz olarak elimize ulaşacaktır.</p>
    <p>Satıcıya ulaşan iade ürün iş bu sözleşmede belirtilen koşulları sağladığı takdirde iade olarak kabul edilir, geri ödemesi de alıcı kredi kartına/hesabına yapılır. Ürün iade edilmeden bedel iadesi yapılmaz. Kredi kartına yapılan iadelerin kredi kartı hesaplarına yansıma süresi ilgili bankanın tasarrufundadır.</p>
    <p>Alışveriş kredi kartı ile ve taksitli olarak yapılmışsa, kredi kartına iade prosedürü şu şekilde uygulanacaktır: Alıcı ürünü kaç taksit ile satın alma talebini iletmiş ise, Banka alıcıya geri ödemesini taksitle yapmaktadır. Satıcı, bankaya ürün bedelinin tamamını tek seferde ödedikten sonra, banka poslarından yapılan taksitli harcamaların alıcının kredi kartına iadesi durumunda konuya müdahil tarafların mağdur duruma düşmemesi için talep edilen iade tutarları, yine taksitli olarak hamil taraf hesaplarına Banka tarafından aktarılır.</p>
    <p>Alıcının satış iptaline kadar ödemiş olduğu taksit tutarları, eğer iade tarihi ile kartın hesap kesim tarihleri çakışmazsa her ay karta 1(bir) iade yansıyacak ve alıcı iade öncesinde ödemiş olduğu taksitleri satışın taksitleri bittikten sonra, iade öncesinde ödemiş olduğu taksit sayısı kadar ay daha alacak ve mevcut borçlarından düşmüş olacaktır.</p>
    <p>Kart ile alınmış mal ve hizmetin iadesi durumunda satıcı, Banka ile yapmış olduğu sözleşme gereği alıcıya nakit para ile ödeme yapamaz.</p>
    <p>Üye işyeri yani satıcı, bir iade işlemi söz konusu olduğunda ilgili yazılım aracılığı ile iadesini yapacak olup, üye işyeri yani satıcı ilgili tutarı Bankaya nakden veya mahsuben ödemekle yükümlü olduğundan yukarıda detayları belirtilen prosedür gereğince alıcıya nakit olarak ödeme yapılamamaktadır. Kredi kartına iade, alıcının Bankaya bedeli tek seferde ödemesinden sonra, Banka tarafından yukarıdaki prosedür gereğince yapılacaktır.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};