import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AYDINLATMA METNİ" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
    <h3>Veri Sorumlusu ve Temsilcisi</h3>
    <p>Anayasal bir hak olarak düzenlenen kişisel verilerin korunması ve hukuksal güvence altına alınması, temel hak ve özgürlükler açısından önem taşımaktadır. Firmamız ile ilişkili tüm gerçek kişilere ait her türlü kişisel verinin 6698 sayılı Kişisel Verilerin Korunması Kanunu’na (“Kanun”) ve Avrupa Birliği Veri Koruma Direktifi’ne (“GDPR”) uygun olarak işlenmesine ve muhafaza edilmesine büyük önem atfetmektedir. Kişisel verileriniz; veri sorumlusu olarak Firmamız tarafından aşağıda açıklanan kapsamda işlenebilecektir.</p>
    <h3>Kişisel Verilerin Hangi Amaçla İşleneceği</h3>
    <p>Elde edilen kişisel veriler;</p>
    <ul>
        <li>Firmamız tarafından yürütülen faaliyetlerin gerçekleştirilmesi için gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi kapsamında; bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi, iş sürekliliğinin sağlanması faaliyetlerinin planlanması veya icrası, kurumsal iletişim faaliyetlerinin planlanması veya icrası, etkinlik yönetimi, tedarik faaliyetlerinin yürütülmesi, kurumsal sürdürülebilirlik faaliyetlerin planlanması veya icrası,</li>
        <li>İlgili mevzuat ve sözleşmeler kapsamında çalışan veya çalışan adayı verilerinin işlenmesi ve staj/mesleki eğitim/burs/eğitim programı faaliyetlerinin yürütülmesi,</li>
        <li>İlgili mevzuattan kaynaklanan saklama, bilgilendirme, raporlama ve mevzuatlarla tanımlanan diğer yükümlülüklerin yerine getirilmesi,</li>
        <li>Firmamız tarafından sunulan ürün, hizmet ve sağlanacak desteklerden faydalanacak ilgili kişiler için gerekli çalışmaların yapılması ve ilgili iş süreçlerinin yürütülmesi kapsamında kişisel verilerin işlenmesi,</li>
        <li>Firmamız tarafından sunulan ürün, hizmet ve sağlanacak desteklerden faydalanacak ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması veya icrası kapsamında kişisel verilerin işlenmesi</li>
    </ul>
    <p>amaçlarıyla; Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları dâhilinde işlenebilecektir.</p>
    <h3>İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</h3>
    <p>Toplanan kişisel verileriniz; yukarıda belirtilen amaçların gerçekleştirilmesi doğrultusunda, pay sahiplerimize, iştirak ve bağlı ortaklıklarımıza, iş ortaklarımıza ve mevzuat uyarınca yetkili kamu kurumlarına veya üçüncü kişilere bu amaçların yerine getirilmesi ile sınırlı olarak Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde işlenebilecektir.</p>
    <p>Kişisel Verileri Koruma Kurulu (“Kurul”) tarafından yeterli korumaya sahip olduğu tespit ve ilan edilen yabancı ülkelerde mukim kişi ve/veya kuruluşlara veya kişisel verilerin aktarıldığı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt ettiği ve Kurulun izninin temin edilebildiği hallerle sınırlı olmak kaydıyla Kurulca tespit ve ilan edilenlerin dışındaki ülkelere aktarılabilecektir.</p>
    <p>GDPR’ın 28’inci maddesine uygun olarak, Firmamıza e-posta gönderimi ve bağlantılı faaliyetler konusunda destek sağlayan dış hizmet sağlayıcılardan veri işleyici olarak Firmamız hizmet alabilir. Bu hizmet sağlayıcılar verileri sadece Firmamızın talimatları doğrultusunda ve kontrolü altında ve yalnızca bu Kişisel Verilerin Korunması Hakkında Aydınlatma Metni’nde belirtilen amaçlara yönelik olarak işlemektedir.</p>
    <h3>Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h3>
    <p>Kişisel verileriniz Firmamız tarafından farklı iletişim kanalları ile farklı hukuki veya ticari sebeplere dayanarak, fiziki veya elektronik ortamlarda, size fayda sağlanması, Firmamız içi gerekliliklerin yerine getirilmesi/faaliyetlerin sürdürülmesi, geliştirilmesi veya mevzuattan doğan yükümlülüklerin yerine getirilmesine yönelik olarak toplanmakta ve muhafaza edilmektedir. Bu Aydınlatma Metni’nin (b) bendinde sayılan amaçlara yönelik olarak verilerinizin işlenmesi ve tarafınızla iletişime geçilmesinin hukuki sebebi bu konuda rıza göstermiş olmanızdır (Kanun’un 5. maddesi / GDPR’ın 6 (1) s. 1 a sayılı maddesi).</p>
    <h3>Kişisel Veri Sahibinin Hakları</h3>
    <p>Kişisel veri sahiplerinin Kanun ve yürürlükte bulunan sair mevzuat (özellikle de Kanun’un 11. maddesi GDPR’ın 15. ile 21. maddeleri) çerçevesinde sahip olduğu haklar aşağıda belirtilmiştir.</p>
    <ul>
        <li>Kişisel verilerinin işlenip işlenmediğini öğrenme,</li>
        <li>Kişisel verilerinin işlendiği durumlarda buna ilişkin bilgi talep etme,</li>
        <li>Kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
        <li>Yurt içinde veya yurt dışında kişisel verilerinin aktarıldığı üçüncü kişileri bilme,</li>
        <li>Kişisel verilerinin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
        <li>Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinin silinmesini veya yok edilmesini isteme ve bu istek kapsamında yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
        <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
        <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme,</li>
        <li>Rızanın, etkisi geleceği kapsayacak şekilde (o tarihe dek yapılan veri işleme faaliyetlerinin meşru kalması kaydıyla) istenildiği zaman geri çekilmesi,</li>
        <li>Kişisel verilerinin doğru olduğuna itiraz etme veya işleme faaliyetinin hukuksuz olması ancak kişisel verilerinin silinmesini arzu etmemesi halinde, kişisel verilerinin işlenmesine sınırlama getirilmesini talep etme,</li>
        <li>Kişisel verilerinin makinelerce okunabilen yapılandırılmış ve standardize edilmiş bir formatta alma ve bu verilerin Firmamız tarafından üçüncü şahıslara ibraz edilmesini talep etme (ayrıntılar ve kısıtlamalar GDPR Madde 20’de belirtilmektedir; bu haktan istifade ediliyor olması verilerinin silinmesini talep etme hakkını etkilemeyecektir),</li>
        <li>Kişisel verilerinin geçerli veri koruma yönergelerine aykırı olarak işlediği kanaatinde olunması halinde, öncelikle Avrupa Birliği’nde yaşanıyorsa mutat meskeninin veya işyeri olan veya veri koruma ihlalinin gerçekleştiği düşünülen Üye Ülke’deki ilgili denetim düzenleyici otorite olmak üzere, yetkili düzenleyici otoritede şikâyette bulunmak hakkı</li>
    </ul>
    <h3>Kişisel Verilerin Saklanma Süresi</h3>
    <p>İşbu Aydınlatma Metninde anılan kanallar vasıtasıyla Firmamız ile paylaşmış olduğunuz kişisel veriler, Kanun ve ilgili mevzuatta süre öngörülmüş ise, öngörülen sürelere uygun şekilde saklanacaktır. Kanun ve ilgili mevzuatta öngörülmüş sürenin sona ermesi akabinde imha edilecektir.</p>
    <p>Saklanan, kaydedilen verilerin kaybolmaması, yetkisiz kişilerin eline geçmemesi, yetkisiz kişilerce değiştirilmemesi, çalınmaması, yanlış ve hukuka aykırı kullanımların önlenmesi için gerekli güvenlik önlemleri Firmamız alınmaktadır.</p>
    <p>Kanun’un “Veri Sorumlusuna Başvuru” başlıklı 13. maddesinin 1. fıkrası uyarınca, yukarıda belirtilen haklarınızı kullanmak ve ilgili talebinizi, yazılı veya Kişisel Verileri Koruma Kurulu’nun belirlediği diğer yöntemlerle Firmamıza iletebilirsiniz.</p>
    <p>Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi belirli ve anlaşılır yapacak gerekli bilgiler ile Kanun’un 11. maddesinde belirtilen hakları kullanmayı talep ettiğiniz hakkınıza yönelik açıklamalarınızı içeren talebinizi; <a href="https://feedup.ai">https://feedup.ai</a> adresindeki formu doldurarak ve formun imzalı bir nüshasını  Fenerbahçe, İÇ KAPI, Iğrıp Sk. NO: 13 no 1, 34726 Kadıköy/İstanbul adresinde bulunan Şirket Müdürlüğü’ne kimliğimi tespit edici belgeler ile bizzat elden iletme yahut noter kanalıyla veya KVK Kanunu’nda belirtilen diğer yöntemler ile iletebilirsiniz.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};