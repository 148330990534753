import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex justify-center items-center w-full`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "MESAFELİ SATIŞ SÖZLEŞMESİ" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <div>
                <p>
                    Madde 1 – Taraflar:
                </p>
            </div>
            <div>
                <p>
                    1.1. Satıcı
                </p>
            </div>
            <div>
                <p>
                    Unvanı  : FeedUp AI Teknoloji Yazılım ve Danışmanlık A.Ş.
                </p>
            </div>
            <div>
                <p>
                    Adresi  : Fenerbahçe, İÇ KAPI, Iğrıp Sk. NO: 13 no 1, 34726
                    Kadıköy/İstanbul
                </p>
            </div>
            <div>
                <p>
                    Telefon : +90 507 487 7035
                </p>
            </div>
            <div>
                <p>
                    Faks    :
                </p>
            </div>
            <div>
                <p>
                    Email   : info@feedup.ai
                </p>
            </div>
            <div>
                <p>
                    Vergi Dairesi   : Göztepe Vergi Dairesi Müd.
                </p>
            </div>
            <div>
                <p>
                    Vergi No        : 3852069236
                </p>
            </div>
            <div>
                <p>
                    Mersis No       : 0385206923600001
                </p>
            </div>
            <div>
                <p>
                    1.2. Alıcı
                </p>
            </div>
            <div>
                <p>
                    Adı / Unvanı    :
                </p>
            </div>
            <div>
                <p>
                    (TC. No/Mersis No)      :
                </p>
            </div>
            <div>
                <p>
                    Adresi  :
                </p>
            </div>
            <div>
                <p>
                    Telefon :
                </p>
            </div>
            <div>
                <p>
                    E-mail  :
                </p>
            </div>
            <div>
                <p>
                    Vergi Dairesi   :
                </p>
            </div>
            <div>
                <p>
                    Vergi No        :
                </p>
            </div>
            <div>
                <p>
                    Not : Vergi Mükellefi değilseniz vergi dairesi ve numarasını boş
                    bırakınız.
                </p>
            </div>
            <div>
                <p>
                    Bundan sonra Alıcı ve Satıcı ayrı ayrı “Taraf”, birlikte “Taraflar”
                    olarak anılacaktır.
                </p>
            </div>
            <div>
                <p>
                    Madde 2 – Konu:
                </p>
            </div>
            <div>
                <p>
                    İşbu Mesafeli Satış Sözleşme’nin (“Sözleşme”) konusu, Alıcı’nın
                    Satıcı’ya ait https://feedup.ai internet sitesinden elektronik ortamda
                    siparişini gerçekleştirdiği; aşağıda nitelikleri ve satış ücreti
                    belirtilen, ilgi web sitesinde nitelikleri sunulan elektronik kartların
                    (“Ürün”) Alıcı tarafından talep edilen vasıf nitelik ve nicelikte satışı
                    ve teslimi ile ilgili olarak, tacir olan Alıcılar için 6102 sayılı Türk
                    Ticaret Kanunu ve tüketici olan Alıcılar için 6502 sayılı Tüketicilerin
                    Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik
                    hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır.
                </p>
            </div>
            <div>
                <p>
                    Alıcı kendisinin; Satıcının isim, unvan, açık adres, telefon ve diğer
                    erişim bilgileri, satışa konu Ürünün temel nitelikleri, vergiler dahil
                    olmak üzere satış fiyatı, ödeme sekli, teslimat koşulları ve masrafları
                    dahil satışa konu Ürün ile ilgili tüm ön bilgiler, "cayma” hakkının
                    kullanılması ve bu hakkın nasıl kullanılacağı, şikayet ve itirazlarını
                    iletebilecekleri resmi makamlar dahil ilgili kurumlar hususlarında açık,
                    anlaşılır ve internet ortamına uygun şekilde Satıcı tarafından
                    bilgilendirildiğini, bu ön bilgileri elektronik ortamda teyit ettiğini
                    ve sonrasında mal sipariş verdiğini işbu Sözleşme hükümlerince kabul,
                    beyan ve taahhüt eder.
                </p>
            </div>
            <div>
                <p>
                    https://feedup.ai internet sitesinde yer alan ön bilgilendirme ve Alıcı
                    tarafından verilen sipariş üzerine düzenlenen fatura işbu Sözleşmenin
                    ayrılmaz parçalarıdır.
                </p>
            </div>
            <div>
            </div>
            <div>
                <p>
                    Madde 3 – Sözleşme Konusu Ürün/Ödeme/Teslimat Bilgileri
                </p>
            </div>
            <div>
                <p>
                    Elektronik ortamda alınan Ürünün cinsi ve türü, miktarı, ağırlığı,
                    marka/modeli, satış bedeli, ödeme şekli, teslim alacak kişi, teslimat
                    adresi, fatura bilgileri, kargo ücreti aşağıda belirtildiği gibidir.
                    Fatura edilecek kişi (özel veya tüzel) ile Sözleşmeyi yapan Alıcının
                    bilgileri ile aynı olmak zorundadır. Aşağıda yer alan bilgiler doğru ve
                    eksiksiz olmalıdır. Bu bilgilerin doğru olmadığı veya noksan olduğu
                    durumlardan doğacak zararları tamamıyla karşılamayı ve ayrıca bu
                    durumdan oluşabilecek her türlü sorumluluğu Alıcı kabul, beyan ve
                    taahhüt eder.
                </p>
            </div>
            <div>
                <p>
                    Satıcı gerekli gördüğü durumlarda, Alıcının vermiş olduğu bilgiler
                    gerçekle örtüşmediğinde, siparişi durdurma hakkını saklı tutar. Satıcı
                    siparişte sorun tespit ettiği durumlarda Alıcının vermiş olduğu telefon,
                    e-posta ve posta adreslerinden Alıcıya ulaşamadığı takdirde siparişin
                    yürürlüğe koyulmasını 15 (onbeş) gün süreyle dondurur. Alıcının bu süre
                    zarfında Satıcı ile konuyla ilgili olarak iletişime geçmesi beklenir. Bu
                    süre içerisinde Alıcıdan herhangi bir cevap alınamazsa Satıcı, her iki
                    tarafın da zarar görmemesi için siparişi iptal eder.
                </p>
            </div>
            <div>
                <p>
                    Alınan Ürün
                </p>
            </div>
            <div>
                <p>
                    Adı, kodu               :
                </p>
            </div>
            <div>
                <p>
                    Adet                    :
                </p>
            </div>
            <div>
                <p>
                    Toplam Satış Bedeli     : …. -TL (KDV DAHİL /HARİÇ)
                </p>
            </div>
            <div>
                <p>
                    Ödeme Şekli             : Kredi Kartı/Banka Havalesi (EFT/Havale)
                </p>
            </div>
            <div>
                <p>
                    Teslim Edilecek Kişi    :
                </p>
            </div>
            <div>
                <p>
                    Telefon numarası        :
                </p>
            </div>
            <div>
                <p>
                    Teslim Edilecek Adres:
                </p>
            </div>
            <div>
                <p>
                    Fatura Edilecek Kişi    :
                </p>
            </div>
            <div>
                <p>
                    Fatura Adresi           :
                </p>
            </div>
            <div>
                <p>
                    Vergi Dairesi           :
                </p>
            </div>
            <div>
                <p>
                    Vergi No                :
                </p>
            </div>
            <div>
                <p>
                    Kargo Ücreti            :
                </p>
            </div>
            <div>
                <p>
                    Madde 4 – Sözleşme Tarihi
                </p>
            </div>
            <div>
                <p>
                    Sözleşme tarihi, Alıcı tarafından siparişin verildiği tarih olan
                    .../../….tarihidir.
                </p>
            </div>
            <div>
                <p>
                    Madde 5 – Mücbir Nedenler
                </p>
            </div>
            <div>
                <p>
                    Sözleşmenin imzalandığı tarihte mevcut olmayan veya öngörülmeyen,
                    Tarafların kontrolleri dışında gelişen, ortaya çıkmasıyla Taraflardan
                    birinin ya da her ikisinin de Sözleşme ile yüklendikleri borç ve
                    sorumluluklarını kısmen ya da tamamen yerine getirmelerini ya da bunları
                    zamanında yerine getirmelerini olanaksızlaştıran durumlar, mücbir sebep
                    (doğal afet, savaş, terör, ayaklanma, değişen mevzuat hükümleri, el
                    koyma veya grev, lokavt, üretim ve iletişim tesislerinde önemli ölçüde
                    arıza vb.) olarak kabul edilecektir. Mücbir sebep şahsında gerçekleşen
                    taraf, diğer tarafa durumu derhal ve yazılı olarak bildirecektir.
                </p>
            </div>
            <div>
                <p>
                    Mücbir sebebin devamı esnasında tarafların edimlerini yerine
                    getirememelerinden dolayı herhangi bir sorumlulukları doğmayacaktır.
                    İşbu mücbir sebep durumu 30 (otuz) gün süreyle devam ederse, taraflardan
                    her birinin, tek taraflı olarak fesih hakkı doğmuş olacaktır.
                </p>
            </div>
            <div>
                <p>
                    Madde 6- Satıcının Hak ve Yükümlülükleri
                </p>
            </div>
            <div>
                <p>
                    6.1. Satıcı, Alıcıya karşı Alıcı tacir ise 6102 sayılı Türk Ticaret
                    Kanunu, Alıcı tüketici ise 6502 sayılı Tüketicilerin Korunması
                    Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri
                    uyarınca Sözleşmede kendisine yüklenen edimleri mücbir haller dışında
                    eksiksiz yerine getirmeyi kabul ve taahhüt eder. Tacir olan Alıcılar
                    yönünden; 6102 sayılı Türk Ticaret Kanunu ve teamülden ayrı olarak tacir
                    olan Alıcının mesafeli satış sözleşmesinden ve 6502 sayılı Tüketicilerin
                    Korunması Hakkındaki Kanun ile alt mevzuatından doğan yasal hakkı var
                    ise bu yasal hakları saklıdır.
                </p>
            </div>
            <div>
                <p>
                    6.2.18 (on sekiz) yaşından küçük kişiler https://feedup.ai adresinden
                    alışveriş yapamaz. Satıcı, Alıcının Sözleşmede belirttiği yaşının doğru
                    olduğunu esas alacaktır. Ancak Alıcının yaşını yanlış yazmasından dolayı
                    Satıcıya hiçbir şekilde sorumluluk yüklenemeyecektir.
                </p>
            </div>
            <div>
                <p>
                    6.3.Sistem hatalarından meydana gelen fiyat yanlışlıklarından
                    https://feedup.ai sorumlu değildir. Buna istinaden Satıcı, internet
                    sitesindeki sistemden, dizayndan veya yasadışı yollarla internet
                    sitesine yapılabilecek müdahaleler sebebiyle ortaya çıkabilecek tanıtım,
                    fiyat hatalarından sorumlu değildir. Sistem hatalarına dayalı olarak
                    Alıcı Satıcıdan hak iddiasında bulunamaz.
                </p>
            </div>
            <div>
                <p>
                    6.4. https://feedup.ai adresinden kredi kartı (Visa, MasterCard , vs. )
                    ya da banka havalesi ile alışveriş yapılabilir. Sipariş tarihinden
                    itibaren bir hafta içinde havalesi yapılmayan siparişler iptal edilir.
                    Siparişlerin işleme alınma zamanı, siparişin verildiği an değil, kredi
                    kartı hesabından gerekli tahsilatın yapıldığı ya da havalenin (EFT’nin)
                    banka hesaplarına ulaştığı belirlenen andır. Ödemeli gönderi ya da posta
                    çeki gibi müşteri hizmetleri ile görüşülmeden gerçekleştirilen ödeme
                    yöntemleri kabul edilmez.
                </p>
            </div>
            <div>
                <p>
                    Madde 7 – Alıcının Hak ve Yükümlülükleri
                </p>
            </div>
            <div>
                <p>
                    7.1. Alıcı, Sözleşmede kendisine yüklenen edimleri mücbir sebepler
                    dışında eksiksiz yerine getirmeyi kabul ve taahhüt eder.
                </p>
            </div>
            <div>
                <p>
                    7.2. Alıcı tacir ise 6102 sayılı Türk Ticaret Kanunu ve bağlı alt
                    mevzuata tabi olduğunu, Alıcı tüketici ise 6502 sayılı Yasa ve alt
                    mevzuata tabi olduğunu kabul, beyan ve taahhüt eder. Alıcının şahıs
                    olması tüketici olduğu anlamına gelmez. Alıcının kimin adına fatura
                    ettirdiği, fatura ettirilen muhatabın kimliği bu konuda esastır.
                </p>
            </div>
            <div>
                <p>
                    7.3.Alıcı, sipariş vermekle birlikte iş Sözleşme hükümlerini kabul etmiş
                    sayıldığını ve Sözleşmede belirtilen ödeme şekline uygun ödemeyi
                    yapacağını kabul ve taahhüt eder.
                </p>
            </div>
            <div>
                <p>
                    7.4.Alıcı, https://feedup.ai internet sitesinden Satıcının isim, unvan,
                    açık adres, telefon ve diğer erişim bilgileri , satışa konu Ürünün temel
                    nitelikleri, vergiler dahil olmak üzere satış fiyatı, ödeme sekli,
                    teslimat koşulları ve masrafları dahil satışa konu Ürün ile ilgili tüm
                    ön bilgiler, "cayma” hakkının kullanılması ve bu hakkın nasıl
                    kullanılacağı, şikayet ve itirazlarını iletebilecekleri resmi makamlar
                    dahil ilgili kurumlar hususlarında açık, anlaşılır ve internet ortamına
                    uygun şekilde bilgi sahibi olduğunu bu ön bilgileri elektronik ortamda
                    teyit ettiğini kabul ve beyan eder.
                </p>
            </div>
            <div>
                <p>
                    7.5. Bir önceki maddeye bağlı olarak Alıcı, Ürün sipariş ve ödeme
                    koşullarının, Ürün kullanım talimatlarının, olası durumlara karşı alınan
                    tedbirlerin ve yapılan uyarıların olduğu sipariş/ödeme/kullanım
                    prosedürü bilgilerini okuyup bilgi sahibi olduğunu ve elektronik ortamda
                    gerekli teyidi verdiğini beyan eder.
                </p>
            </div>
            <div>
                <p>
                    7.6. Alıcı, aldığı Ürünü iade etmek istemesi durumunda ne surette olursa
                    olsun Ürüne ve ambalajına zarar vermemeyi, iade anında fatura aslını ve
                    irsaliyesini iade etmeyi kabul, beyan ve taahhüt eder.
                </p>
            </div>
            <div>
                <p>
                    Madde 8 – Sipariş/Ödeme Prosedürü
                </p>
            </div>
            <div>
                <p>
                    Sipariş:
                </p>
            </div>
            <div>
                <p>
                    Alışveriş sepetine eklenen ürünlerin KDV dahil USD tutarı (Taksitli
                    işlemlerde toplam taksit tutarları) Alıcı tarafından onaylandıktan
                    sonra, ilgili banka kartının posu üzerinden işleme alınır. Bu nedenle
                    siparişler, sevk edilmeden önce müşteriye sipariş onay maili gönderilir.
                    Sipariş onay maili gönderilmeden sevkiyat yapılmaz.
                </p>
            </div>
            <div>
                <p>
                    Süreçteki herhangi bir aksama durumu ya da kredi kartı ile ilgili ortaya
                    çıkabilecek problemler Alıcıya Sözleşmede belirttiği telefon/faks/e-mail
                    yollarından biri veya birkaçı kullanılmak sureti ile bildirilir.
                    Gerekirse Alıcıdan bankası ile görüşmesi istenebilir. Siparişlerin
                    işleme alınma zamanı, siparişin verildiği an değil, kredi kartı
                    hesabından gerekli tahsilatın yapıldığı ya da havalenin (EFT’ nin)
                    Satıcı hesaplarına ulaştığının belirlendiği andır.
                </p>
            </div>
            <div>
                <p>
                    İstisnai olarak haklı bir nedenle sözleşme konusu malın tedarik
                    edilemeyeceğinin anlaşılması ve/veya stok problemi ile karşılaşılması
                    durumunda alıcı hemen açık ve anlaşılır bir şekilde bilgilendirilip onay
                    vermesi durumunda Alıcıya eşit kalitede ve fiyatta başka bir ürün
                    gönderilebilir ya da Alıcının arzusu ve seçimi doğrultusunda; yeni başka
                    bir ürün gönderilebilir, Ürünün stoklara girmesi ya da teslime engel
                    diğer engelin ortadan kalkması beklenebilir ve/veya sipariş iptal
                    edilebilir.
                </p>
            </div>
            <div>
                <p>
                    Sözleşme konusu Ürünün teslim yükümlülüğünün yerine getirilmesinin
                    imkânsızlaştığı hâllerde Alıcı bu durumdan haberdar edilerek ödemiş
                    olduğu toplam bedel ve varsa onu borç altına sokan her türlü belge en
                    geç on gün içinde kendisine iade edilerek Sözleşme iptal edilir. Böyle
                    bir durumda Alıcının Satıcıdan ilave herhangi bir maddi ve manevi zarar
                    talebi olmayacaktır.
                </p>
            </div>
            <div>
                <p>
                    Ödeme:
                </p>
            </div>
            <div>
                <p>
                    https://feedup.ai adresinde, internet ortamında kredi kartı bilgilerini
                    kullanmak istemeyen Alıcılara, Satıcının takdir yetkisine bağlı olarak,
                    nakit havale ile sipariş imkanları sunulabilir. Böyle bir durumda,
                    havale ile ödemede Alıcı kendisine en uygun bankayı seçip havalesini
                    yapabilir. Eğer EFT yapılmışsa hesaba geçme tarihi dikkate alınacaktır.
                    Havale ve/veya EFT yaparken "Gönderen Bilgileri”nin fatura bilgileri ile
                    aynı olması ve sipariş numarasının yazılması gereklidir.
                </p>
            </div>
            <div>
                <p>
                    Ürünün tesliminden sonra Alıcıya ait kredi kartının Alıcının kusurundan
                    kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı
                    olarak kullanılması nedeni ile ilgili banka veya finans kuruluşun ürün
                    bedelini Satıcıya ödememesi halinde, Alıcının kendisine teslim edilmiş
                    ürünü 10 (on) gün içinde Satıcıya göndermesi zorunludur. Bu tür
                    durumlarda nakliye giderleri Alıcıya aittir.
                </p>
            </div>
            <div>
                <p>
                    Alıcı kredi kartı ile ödeme yapmayı tercih etmiş ise Alıcı, ilgili faiz
                    oranlarını ve temerrüt faizi ile ilgili bilgileri bankasından ayrıca
                    teyit edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince faiz ve
                    temerrüt faizi ile ilgili hükümlerin banka ve Alıcı arasındaki "Kredi
                    Kartı Sözleşmesi” kapsamında uygulanacağını kabul, beyan ve taahhüt
                    eder.
                </p>
            </div>
            <div>
                <p>
                    Madde 9 – Sevkiyat/Teslimat Prosedürü
                </p>
            </div>
            <div>
                <p>
                    Sevkiyat:
                </p>
            </div>
            <div>
                <p>
                    Sipariş onayı mailinin gönderilmesiyle birlikte, Ürün Satıcının
                    anlaşmalı olduğu kargo şirketine verilir.
                </p>
            </div>
            <div>
                <p>
                    Teslimat:
                </p>
            </div>
            <div>
                <p>
                    Ürün/ürünler satıcının anlaşmalı olduğu kargo ile Alıcının adresine
                    teslim edilecektir. Teslimat süresi, sipariş onayı mailinin
                    gönderilmesinden ve sözleşmenin kurulmasından itibaren 30 gündür.
                    Alıcıya önceden yazılı olarak veya bir sürekli veri taşıyıcısıyla
                    bildirilmek koşuluyla bu süre en fazla on gün uzatılabilir.
                </p>
            </div>
            <div>
                <p>
                    Ürünler, kargo şirketlerinin adres teslimatı yapmadığı bölgelere telefon
                    ihbarlı olarak gönderilir.
                </p>
            </div>
            <div>
                <p>
                    Kargo şirketinin haftada bir gün teslimat yaptığı bölgelerde, sevk
                    bilgilerindeki yanlışlık ve eksiklik olduğu hallerde, bazı sosyal
                    olaylar ve doğal afetler gibi durumlarda belirtilen gün süresinde sarkma
                    olabilir. Bu sarkmalardan dolayı alıcı satıcıya herhangi bir sorumluluk
                    yükleyemez. Ürün, Alıcıdan başka bir kişi/kuruluşa teslim edilecek ise,
                    teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden, sevk
                    bilgilerindeki yanlışlık ve/veya Alıcının yerinde olmamasından
                    doğabilecek ekstra kargo bedelleri de dahil olmak üzere zararlardan ve
                    giderlerden Satıcı sorumlu değildir. Belirtilen günler içeriğinde
                    ürün/ürünler müşteriye ulaşmadıysa teslimat problemleri müşteri
                    hizmetlerine
                    <a
                        href="mailto:info@feedup.ai"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        info@feedup.ai
                    </a>
                    e-mail adresi kullanılmak sureti ile derhal bildirilmelidir.
                </p>
            </div>
            <div>
                <p>
                    Zarar görmüş paket durumunda; zarar görmüş paketler teslim alınmayarak
                    kargo şirketi yetkilisine tutanak tutturulmalıdır. Eğer kargo şirketi
                    yetkilisi paketin hasarlı olmadığı görüşünde ise, paketin orada açılarak
                    ürünlerin hasarsız teslim edildiğini kontrol ettirme ve durumun yine bir
                    tutanakla tespit edilmesini isteme hakkı alıcıda vardır. Paket Alıcı
                    tarafından teslim alındıktan sonra kargo şirketinin görevini tam olarak
                    yaptığı kabul edilmiş olur. Paket kabul edilmemiş ve tutanak tutulmuş
                    ise, durum, tutanağın Alıcıda kalan kopyasıyla birlikte en kısa zamanda
                    Satıcı müşteri hizmetlerine bildirilmelidir.
                </p>
            </div>
            <div>
                <p>
                    Madde 10 – Ürün İade ve Cayma Hakkına İlişkin Prosedürü
                </p>
            </div>
            <div>
                <p>
                    10.1. Alıcı tacir ise 8 (sekiz), Alıcı tüketici ise 14 (on dört) gün
                    olmak üzere; Ürünü teslim aldıktan sonra belirtilen sürede sevk edildiği
                    şekil, şart, nitelik ve pakette olmak ön şartıyla herhangi bir gerekçe
                    göstermeksizin ve cezai şart ödemeksizin Sözleşmeden cayma hakkına
                    sahiptir. 385 sayılı Vergi Usul Kanunu Genel Tebliği uyarınca iade
                    işlemlerinin yapılabilmesi için Alıcının mal ile birlikte teslim edilen
                    Satıcıya ait 2 adet faturanın alt kısmındaki iade bölümlerini eksiksiz
                    ve doğru şekilde doldurduktan sonra imzalayarak bir nüshasını ürün ile
                    birlikte Satıcıya göndermesi diğer nüshasını da uhdesinde tutması
                    gerekmektedir. Cayma hakkı süresi Alıcıya Ürünün teslim edildiği günden
                    itibaren başlar. İade edilen Ürünün geri gönderim bedeli Alıcı
                    tarafından karşılanmalıdır.
                </p>
            </div>
            <div>
                <p>
                    10.2. Alıcının istekleri ve/veya açıkça onun kişisel ihtiyaçları
                    doğrultusunda hazırlanan mallar için cayma hakkı söz konusu değildir.
                </p>
            </div>
            <div>
                <p>
                    10.3. Alıcının cayma hakkını kullanması halinde Satıcı, cayma
                    bildirimini içeren faturanın Ürünle birlikte kendisine ulaşmasından
                    itibaren en geç on gün içerisinde almış olduğu toplam bedeli ve Alıcı
                    tüketici ise tüketiciyi borç altına sokan her türlü belgeyi tüketiciye
                    hiçbir masraf yüklemeden iade edecektir.
                </p>
            </div>
            <div>
                <p>
                    10.4. Ambalajı açılmış, kullanılmış, tahrip edilmiş vesaire şekildeki
                    ürünlerin iadesi kabul edilmez. İade, orijinal ambalaj ile yapılmalıdır.
                    Sehven alınan Üründe ve/veya Ürünün ambalajında herhangi bir açılma,
                    bozulma, kırılma, tahrip, yırtılma, kullanılma ve sair durumlar tespit
                    edildiği hallerde ve Ürünün Alıcıya teslim edildiği andaki hali ile iade
                    edilememesi durumunda Ürün iade alınmaz ve bedeli iade edilmez.
                </p>
            </div>
            <div>
                <p>
                    10.5. Ürün iadesi için, durum öncelikli olarak müşteri hizmetlerine
                    iletilmelidir. Ürünün iade olarak gönderilme bilgisi, Satıcı tarafından
                    Alıcıya iletilir. Bu görüşmeden sonra Ürün iade ile ilgili bilgileri
                    içeren fatura ile birlikte Alıcı adresine teslimatı yapan kargo şirketi
                    kanalıyla Satıcıya ulaştırmalıdır. Satıcıya ulaşan iade Ürün iş bu
                    sözleşmede belirtilen koşulları sağladığı takdirde iade olarak kabul
                    edilir, geri ödemesi de Alıcı kredi kartına/hesabına yapılır. Ürün iade
                    edilmeden bedel iadesi yapılmaz. Kredi kartına yapılan iadelerin kredi
                    kartı hesaplarına yansıma süresi ilgili bankanın tasarrufundadır.
                </p>
            </div>
            <div>
                <p>
                    10.6. Alışveriş kredi kartı ile ve taksitli olarak yapılmışsa, kredi
                    kartına iade prosedürü şu şekilde uygulanacaktır: Alıcı Ürünü kaç taksit
                    ile satın alma talebini iletmiş ise, Banka Alıcıya geri ödemesini
                    taksitle yapmaktadır. Satıcı, bankaya Ürün bedelinin tamamını tek
                    seferde ödedikten sonra, banka poslarından yapılan taksitli harcamaların
                    Alıcının kredi kartına iadesi durumunda konuya müdahil Tarafların mağdur
                    duruma düşmemesi için talep edilen iade tutarları, yine taksitli olarak
                    hamil taraf hesaplarına banka tarafından aktarılır. Alıcının satış
                    iptaline kadar ödemiş olduğu taksit tutarları, eğer iade tarihi ile
                    kartın hesap kesim tarihleri çakışmazsa her ay karta 1 (bir) iade
                    yansıyacak ve Alıcı iade öncesinde ödemiş olduğu taksitleri satışın
                    taksitleri bittikten sonra, iade öncesinde ödemiş olduğu taksit sayısı
                    kadar ay daha alacak ve mevcut borçlarından düşmüş olacaktır.
                </p>
            </div>
            <div>
                <p>
                    10.7. Kart ile alınmış Ürünün iadesi durumunda Satıcı, banka ile yapmış
                    olduğu sözleşme gereği Alıcıya nakit para ile ödeme yapamaz. Üye işyeri
                    yani Satıcı, bir iade işlemi söz konusu olduğunda ilgili yazılım
                    aracılığı ile iadesini yapacak olup, üye işyeri yani Satıcı ilgili
                    tutarı bankaya nakden veya mahsuben ödemekle yükümlü olduğundan yukarıda
                    detayları belirtilen prosedür gereğince Alıcıya nakit olarak ödeme
                    yapılamamaktadır. Kredi kartına iade, Alıcının bankaya bedeli tek
                    seferde ödemesinden sonra, banka tarafından yukarıdaki prosedür
                    gereğince yapılacaktır.
                </p>
            </div>
            <div>
                <p>
                    Madde 11 – Garanti
                </p>
            </div>
            <div>
                <p>
                    Ticari nitelikteki işler için 6102 sayılı Türk Ticaret Kanunu hükümleri
                    geçerli olacaktır. <br/>
                    <br/>
                    Madde 12 – Gizlilik
                </p>
            </div>
            <div>
                <p>
                    Alıcı tarafından işbu Sözleşmede belirtilen bilgiler ile ödeme yapmak
                    amacı ile Satıcıya bildirdiği bilgiler Satıcı tarafından 3. şahıslarla
                    paylaşılmayacaktır. Satıcı bu bilgileri sadece idari/yasal zorunluluğun
                    mevcudiyeti çerçevesinde açıklayabilecektir. Araştırma ehliyeti
                    belgelenmiş her türlü adli soruşturma dahilinde satıcı kendisinden
                    istenen bilgiyi elinde bulunduruyorsa ilgili makama sağlayabilir.
                </p>
            </div>
            <div>
                <p>
                    Alıcıya ait e-posta adresi, posta adresi ve telefon gibi bilgiler
                    yalnızca Satıcı tarafından standart ürün teslim ve bilgilendirme
                    prosedürleri için kullanılır. Bazı dönemlerde kampanya bilgileri, yeni
                    ürünler hakkında bilgiler, promosyon bilgileri Alıcıya onayı sonrasında
                    gönderilebilir.
                </p>
            </div>
            <div>
                <p>
                    Madde 13 – Kişisel Verilerin Korunması ve Fikri-Sınai Haklar
                </p>
            </div>
            <div>
                <p>
                    13.1. Satıcı, işbu Sözleşme kapsamındaki kişisel verileri sadece Ürünün
                    sunulması amacıyla sınırlı olarak ve 6698 sayılı Kişisel Verilerin
                    Korunması Kanunu’na, (“KVKK”) ikincil mevzuata ve Kişisel Verileri
                    Koruma Kurulu kararlarına uygun olarak işleyecektir. Satıcı,
                    <a href="https://feedup.ai/" target="_blank" rel="noreferrer noopener">
                        https://feedup.ai
                    </a>
                    internet sitesi üzerinden eriştiği kişisel veriler haricinde Alıcının
                    kişisel verilerini işlemeyeceğini ve belirtilen internet süresi
                    üzerinden sağlanan yöntemler dışında Alıcı ile harici olarak iletişime
                    geçmeyeceğini kabul, beyan ve taahhüt eder.
                </p>
            </div>
            <div>
                <p>
                    13.2. Alıcı, işbu Sözleşme kapsamında sağladığı kişisel verilerin doğru,
                    eksiksiz ve güncel olduğunu kontrol etmekle, bu bilgileri üçüncü
                    kişilerle paylaşmamak, ilgisiz kişilerce erişilememesi için virüs ve
                    benzeri zararlı uygulamalara ilişkin olanlar dahil gerekli tedbirleri
                    almak ve söz konusu kişisel verilerin güvenliğini sağlamakla yükümlü
                    olduğunu, aksi halde doğacak zararlardan ve üçüncü kişilerden gelen
                    taleplerden bizzat sorumlu olduğunu kabul, beyan ve taahhüt eder.
                </p>
            </div>
            <div>
                <p>
                    13.3. 
                    <a href="https://feedup.ai/" target="_blank" rel="noreferrer noopener">
                        https://feedup.ai
                    </a>
                    internet sitesine ait her türlü bilgi ve içerik ile bunların
                    düzenlenmesi, revizyonu ve kısmen/tamamen kullanımı konusunda; Satıcının
                    anlaşmasına göre diğer üçüncü şahıslara ait olanlar hariç; tüm
                    fikri-sınai haklar ve mülkiyet hakları Satıcıya aittir.
                </p>
            </div>
            <div>
                <p>
                    Madde 15 – Bildirimler ve Delil Sözleşmesi
                </p>
            </div>
            <div>
                <p>
                    15.1. Sözleşme tahtında Taraflar arasında yapılacak her türlü yazışma,
                    mevzuatta sayılan zorunlu haller dışında, yazılı olarak yapılacaktır.
                </p>
            </div>
            <div>
                <p>
                    15.2. Alıcı, Sözleşmeden doğabilecek ihtilaflarda Satıcının ticari
                    defter, bilgisayar, kayıt ve diğer belgelerinin bağlayıcı, kesin ve
                    münhasır delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri
                    Kanunu'nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu
                    kabul, beyan ve taahhüt eder.
                </p>
            </div>
            <div>
                <p>
                    Madde 16- Uyuşmazlık Durumunda Yetkili Mahkeme ve İcra Daireleri
                </p>
            </div>
            <div>
                <p>
                    İşbu sözleşmenin uygulanmasından kaynaklanan uyuşmazlık halinde,
                </p>
            </div>
            <div>
                <p>
                    Alıcı tacir ise İstanbul Merkez Adliye Mahkeme ve İcra Daireleri,
                </p>
            </div>
            <div>
                <p>
                    Alıcı tüketici ise; Sanayi ve Ticaret Bakanlığınca her yılın aralık
                    ayında ilan edilen değere kadar Tüketici Hakem Heyetleri ile Alıcının
                    veya Satıcının yerleşim yerindeki Tüketici Mahkemeleri yetkilidir.
                </p>
            </div>
            <div>
                <p>
                    Madde 17 – Yürürlük
                </p>
            </div>
            <div>
                <p>
                    17 (on yedi) maddeden ibaret Sözleşme, Taraflarca okunarak, madde 4’te
                    belirtilen sipariş tarihinde, Alıcı tarafından elektronik ortamda
                    onaylanmak suretiyle akdedilmiş ve yürürlüğe girmiştir.
                </p>
            </div>
            <div>
            </div>
       
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};