import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex justify-center items-center w-full`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "TİCARİ ELEKTRONİK İLETİ GÖNDERİMİ HAKKINDA BİLGİLENDİRME" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
          <div>
            <div>
                <p>
                    6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve
                    ilgili mevzuat uyarınca ticari elektronik ileti; telefon, çağrı
                    merkezleri, faks, otomatik arama makineleri, akıllı ses kaydedici
                    sistemler, elektronik posta, kısa mesaj hizmeti gibi vasıtalar
                    kullanılarak elektronik ortamda gerçekleştirilen ve ticari amaçlarla
                    gönderilen veri, ses ve görüntü içerikli iletileri ifade eder.
                </p>
            </div>
            <div>
                <p>
                    Ticari elektronik iletiler, alıcılara ancak önceden onayları alınmak
                    kaydıyla gönderilebilir. Ticari Elektronik İleti Yönetim Sistemi
                    (“İYS”) üzerinde onayı bulunmayan alıcılara ticari elektronik ileti
                    gönderilemez Bu onay, yazılı olarak veya her türlü elektronik
                    iletişim araçlarıyla ya da İYS (sistemin kullanıma
                    açılması halinde) üzerinden alınabilir. İYS üzerinden alınan
                    onaylarda ise olumlu irade beyanı ve elektronik iletişim adresi yer
                    alır. Alıcılar diledikleri zaman, hiçbir gerekçe belirtmeksizin
                    ticari elektronik iletileri almayı reddedebilir. Alıcının ret
                    bildiriminde bulunması, bildirimin yapıldığı iletişim kanalına
                    ilişkin onayı geçersiz kılar. Alıcı reddetme hakkını İYS (sistemin
                    kullanıma açılması halinde) üzerinden de kullanabilir.
                </p>
            </div>
            <div>
                <p>
                    Alıcının kendisiyle iletişime geçilmesi amacıyla iletişim
                    bilgilerini vermesi hâlinde, temin edilen mal veya hizmetlere
                    ilişkin değişiklik, kullanım ve bakıma yönelik ticari elektronik
                    iletiler için ayrıca onay alınmaz.
                </p>
            </div>
            <div>
                <p>
                    Tacir veya esnaf olan alıcıların elektronik iletişim adreslerine
                    gönderilen ticari elektronik iletiler için önceden onay alınması
                    zorunlu değildir. Ancak tacir ve esnafların ilgili mevzuatta yer
                    alan reddetme hakkını kullanması halinde onayları alınmadan ticari
                    elektronik ileti gönderilemez. Bu kapsamında ileti gönderilmesinden
                    önce tacir veya esnaf olan alıcıların elektronik iletişim adresleri
                    hizmet sağlayıcı tarafından İYS’ye kaydedilir ve İYS üzerinden
                    alıcıların ret hakkını kullanıp kullanmadığı kontrol edilir.
                </p>
            </div>
            <div>
                <p>
                    6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve
                    ilgili mevzuat uyarınca, FeedUp AI tarafından vermekte olduğumuz
                    hizmetler kapsamında, FeedUp AI Websitesi üzerinden temin edilen
                    bilgileriniz doğrultusunda, onay vermeniz halinde herhangi bir
                    iletişim aracı kullanarak (SMS, e-posta, telefon, faks, çağrı
                    merkezi, v.b. gibi) tarafınıza her türlü bilgilendirme, tanıtım,
                    reklam, ürün teklifleri, promosyonlar, kampanyalar, memnuniyet
                    değerlendirme çalışmaları ve duyuruların iletilmesi amacıyla
                    tarafınızla iletişime geçilebilecektir.
                </p>
            </div>
            <div>
                <p>
                    6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve
                    ilgili mevzuat uyarınca ticari elektronik ileti gönderimine dair
                    onay verseniz dahi dilediğiniz zaman, hiçbir gerekçe belirtmeksizin
                    ticari elektronik iletileri almayı reddedebilirsiniz. Ticari
                    elektronik ileti hangi iletişim kanalıyla gönderildiyse ret
                    bildirimi de kolay ve ücretsiz bir şekilde olmak üzere aynı iletişim
                    kanalıyla verebileceğinizi hatırlatmak isteriz. Reddetme hakkınızı
                    İYS (sistemin kullanıma açılması halinde) üzerinden de
                    kullanabilirsiniz.
                </p>
            </div>
            <div>
                <p>
                    TİCARİ ELEKTRONİK İLETİ GÖNDERİMİ RIZA BEYANI
                </p>
            </div>
            <div>
                <p>
                    İşbu beyanı onaylayarak, FeedUp AI’n, 6563 sayılı Elektronik
                    Ticaretin Düzenlenmesi Hakkında Kanun ve ilgili mevzuat uyarınca,
                    Michelin tarafından tarafıma verilmekte olan hizmetler kapsamında,
                    FeedUp AI Websitesi’ne sağladığım ad, soyad ve iletişim bilgilerim
                    vasıtasıyla, herhangi bir iletişim aracı kullanarak (SMS, e-posta,
                    telefon, faks, çağrı merkezi, v.b. gibi) tarafıma her türlü
                    bilgilendirme, tanıtım, reklam, ürün teklifleri, promosyonlar,
                    kampanyalar, memnuniyet değerlendirme çalışmaları ve duyuruların
                    iletilmesi amacıyla tarafımla iletişime geçilmesine açık rıza
                    verdiğimi, bu kapsamda bu bilgilerimde herhangi bir değişiklik
                    olması halinde bu hususu FeedUp AI’a yazılı olarak bildireceğimi ve
                    güncel bilgilerimi içeren yeni bir beyannameyi imzalayarak FeedUp
                    AI’a temin edeceğimi,
                </p>
            </div>
            <div>
                <p>
                    Tarafımca verilmiş işbu rızanın geri alınabilir olduğu konusunda
                    bilgim olduğunu ve bu doğrultuda FeedUp AI’a, ticari elektronik
                    ileti hangi iletişim kanalıyla gönderildiyse ret bildirimi de kolay
                    ve ücretsiz bir şekilde olmak üzere aynı iletişim kanalıyla
                    verebileceğimi, reddetme hakkımı İYS (sistemin kullanıma
                    açılması halinde) üzerinden de kullanabileceğimi bildiğimi kabul,
                    beyan ve taahhüt ederim.
                </p>
            </div>
            <div>
            </div>
        </div>

       
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};